<template>
  <li class="nav-item" :class="{'sidebar-group-active active': isActive}">
    <b-link class="nav-link" :to="{ name: 'Marketplace',query:{ type:item.id } }">
      <feather-icon v-if="item.icon" size="14" :icon="item.icon" />
      <span>{{ t(item.name) }}</span>
    </b-link>
  </li>
</template>

<script>
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import { BLink } from "bootstrap-vue";
import mixinHorizontalNavMenuHeaderLink from "./mixinHorizontalNavMenuHeaderLink";
import useHorizontalNavMenuHeaderLink from "./useHorizontalNavMenuHeaderLink";

export default {
  components: {
    BLink
  },
  mixins: [mixinHorizontalNavMenuHeaderLink],
  props: {
    item: {
      type: Object,
      default: "null"
    }
  },

  setup(props) {
    const { isActive, updateIsActive } = useHorizontalNavMenuHeaderLink(
      props.item
    );

    const { t } = useI18nUtils();

    return {
      isActive,
      updateIsActive,
      t
    };
  }
};
</script>
