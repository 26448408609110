var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"nav-item nav-search",class:_vm.isMobile ? '' : 'cs-navbar'},[_c('a',{staticClass:"nav-link nav-link-search",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){_vm.showSearchBar = true}}},[_c('feather-icon',{attrs:{"icon":"SearchIcon","size":"21"}})],1),_c('div',{staticClass:"search-input",class:{ open: _vm.showSearchBar }},[_c('div',{staticClass:"search-input-icon"},[_c('feather-icon',{attrs:{"icon":"SearchIcon"}})],1),(_vm.showSearchBar)?_c('b-form-input',{staticClass:"ml-4",attrs:{"placeholder":_vm.$t('Tìm kiếm sản phẩm'),"autofocus":"","autocomplete":"off","debounce":"500"},on:{"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }_vm.isMobile ? _vm.showSearchBar = false : _vm.showSearchBar = true;
        _vm.searchHistory = '';
        _vm.resetsearchQuery();},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return (function () { return _vm.toSearchResultByKeyword(); })($event)}],"update":_vm.handleSearch},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}):_vm._e(),((!_vm.isMobile && _vm.searchQuery) || (_vm.isMobile) )?_c('div',{staticClass:"search-input-close",on:{"click":function () {
          _vm.showSearchBar = _vm.isMobile ? false : true;
          _vm.resetsearchQuery();
        }}},[_c('feather-icon',{attrs:{"icon":"XIcon"}})],1):_vm._e(),_c('vue-perfect-scrollbar',{staticClass:"search-list search-list-main scroll-area overflow-hidden",class:{ show: _vm.searchQuery  },attrs:{"settings":_vm.perfectScrollbarSettings,"tagname":"ul"}},[(!_vm.searchQuery)?_vm._l((_vm.searchHistory),function(search,index){return _c('li',{key:index,staticClass:"suggestion-group-suggestion cursor-pointer px-4",class:{
            'suggestion-current-selected': _vm.currentSelected === ("" + index),
          },on:{"mouseenter":function($event){_vm.currentSelected = "" + index},"click":function($event){return _vm.toSearchResultByKeyword(search)}}},[_c('span',{staticClass:"align-middle ml-1 font-medium"},[_vm._v(_vm._s(search))])])}):_vm._e(),(_vm.searchQuery)?_vm._l((_vm.listProducts),function(suggestion,index){return _c('li',{key:index,staticClass:"suggestion-group-suggestion cursor-pointer md:px-4",class:{
            'suggestion-current-selected': _vm.currentSelected === ("" + index),
          },on:{"mouseenter":function($event){_vm.currentSelected = "" + index},"click":function($event){return _vm.goToDetail(suggestion)}}},[_c('div',{staticClass:"p-0 flex justify-between items-center",staticStyle:{"gap":"24px"}},[_c('div',{staticClass:"flex gap-1 items-center"},[_c('img',{staticStyle:{"width":"40px","height":"40px"},attrs:{"src":suggestion.primary_image,"alt":""}}),_c('span',{staticClass:"align-middle ml-1 font-medium"},[_vm._v(" "+_vm._s(suggestion.name)+" ")])]),_c('div',{staticClass:"text-right"},[_c('span',[_vm._v("Thương hiệu:")]),_c('span',{staticClass:"uppercase",staticStyle:{"color":"#20419b","margin-left":"4px"}},[_vm._v(_vm._s(suggestion.brands && suggestion.brands.name))])])])])}):_vm._e(),(!_vm.listProducts.length && _vm.searchQuery)?_c('li',{staticClass:"suggestion-group-suggestion no-results"},[_c('p',[_vm._v("No Results Found.")])]):_vm._e()],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }