<template>
  <b-nav-item-dropdown class="dropdown-notification mr-25" menu-class="dropdown-menu-media" right>
    <template #button-content>
      <feather-icon
        :badge="countNoti.label"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">Notifications</h4>
        <b-badge pill variant="light-primary">{{ countNoti.value }} New</b-badge>
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar
      v-once
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <!-- Account Notification -->
      <b-link v-for="notification in notifications" :key="notification.subtitle">
        <b-media>
          <template #aside>
            <b-avatar
              size="32"
              :src="notification.avatar"
              :text="notification.avatar"
              :variant="notification.type"
            />
          </template>
          <p class="media-heading">
            <span class="font-weight-bolder">{{ notification.title }}</span>
          </p>
          <small class="notification-text">{{ notification.subtitle }}</small>
        </b-media>
      </b-link>

      <!-- System Notification Toggler -->
      <div class="media d-flex align-items-center">
        <h6 class="font-weight-bolder mr-auto mb-0">System Notifications</h6>
        <b-form-checkbox :checked="true" switch />
      </div>

      <!-- System Notifications -->
      <b-link v-for="notification in systemNotifications" :key="notification.subtitle">
        <b-media>
          <template #aside>
            <b-avatar size="32" :variant="notification.type">
              <feather-icon :icon="notification.icon" />
            </b-avatar>
          </template>
          <p class="media-heading">
            <span class="font-weight-bolder">{{ notification.title }}</span>
          </p>
          <small class="notification-text">{{ notification.subtitle }}</small>
        </b-media>
      </b-link>
    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
    <li class="dropdown-menu-footer">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
      >Read all notifications</b-button>
    </li>
  </b-nav-item-dropdown>
</template>

<script>
import {
BAvatar,
BBadge,
BButton,
BFormCheckbox,
BLink,
BMedia,
BNavItemDropdown
} from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import Ripple from "vue-ripple-directive";
import { mapState } from "vuex";

import appUtils from "@/utils/appUtils";
import { NOTIFICATION_TYPE } from "@/utils/constant";

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
    BFormCheckbox
  },
  directives: {
    Ripple
  },
  data() {
    return {
      isShowing: false
    };
  },
  watch: {
    isShowing: {
      handler: function() {
        let self = this;
        const listNoti = document.querySelector("#notification");
        if (listNoti) {
          listNoti.addEventListener("scroll", e => {
            if (
              listNoti.scrollTop + listNoti.clientHeight >=
              listNoti.scrollHeight
            ) {
              const currentPage = this.notificationStore.notificationMeta
                .page_num;
              const totalPage = this.notificationStore.notificationMeta
                .total_page;
              const nextPage = currentPage + 1;

              if (
                nextPage <= totalPage &&
                !this.notificationStore.loading_noti
              ) {
                this.handleGetNotis({ page_num: nextPage }, true);
              }
            }
          });
        }
      },
      deep: true
    }
  },
  computed: {
    ...mapState({
      notificationStore: state => state.notification
    }),
    countNoti() {
      const count = {
        label:
          this.notificationStore.totalNotificationUnread < 10
            ? this.notificationStore.totalNotificationUnread
            : `9+`,
        value: this.notificationStore.totalNotificationUnread
      };
      return count;
    }
  },
  beforeMount() {
    this.handleGetNotis();
  },
  methods: {
    onShowNoti(show) {
      if (show) {
        this.$store.commit("notification/setHasNewMessage", { isNew: false });
        this.handleGetNotis({}, false);
      }

      this.isShowing = show;
    },
    handleGetNotis(metaData, isPush) {
      try {
        this.loading_noti = true;
        const userData = appUtils.getLocalUser();
        const isProcessor = userData?.processors?.length;

        const params = {
          user_id: userData.id,
          type_notification: isProcessor
            ? NOTIFICATION_TYPE.PROCESSOR
            : NOTIFICATION_TYPE.SUPPLIER,
          sort_by: "changed_at",
          order: "desc",
          page_num: 1,
          page_size: 10,
          ...metaData
        };
        this.$store.dispatch("notification/getNotifications", {
          params,
          isPush
        });
      } catch (error) {
      } finally {
        this.loading_noti = true;
      }
    }
  },
  setup() {
    /* eslint-disable global-require */
    const notifications = [
      {
        title: "Congratulation Sam 🎉",
        avatar: require("@/assets/images/avatars/6-small.png"),
        subtitle: "Won the monthly best seller badge",
        type: "light-success"
      },
      {
        title: "New message received",
        avatar: require("@/assets/images/avatars/9-small.png"),
        subtitle: "You have 10 unread messages",
        type: "light-info"
      },
      {
        title: "Revised Order 👋",
        avatar: "MD",
        subtitle: "MD Inc. order updated",
        type: "light-danger"
      }
    ];
    /* eslint-disable global-require */

    const systemNotifications = [
      {
        title: "Server down",
        subtitle: "USA Server is down due to hight CPU usage",
        type: "light-danger",
        icon: "XIcon"
      },
      {
        title: "Sales report generated",
        subtitle: "Last month sales report generated",
        type: "light-success",
        icon: "CheckIcon"
      },
      {
        title: "High memory usage",
        subtitle: "BLR Server using high memory",
        type: "light-warning",
        icon: "AlertTriangleIcon"
      }
    ];

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false
    };

    return {
      notifications,
      systemNotifications,
      perfectScrollbarSettings
    };
  }
};
</script>

<style>
</style>
