<template>
  <li class="nav-item nav-search" :class="isMobile ? '' : 'cs-navbar'">
    <!-- Icon -->
    <a href="javascript:void(0)" class="nav-link nav-link-search" @click="showSearchBar = true">
      <feather-icon icon="SearchIcon" size="21" />
    </a>

    <!-- Input -->
    <div class="search-input" :class="{ open: showSearchBar }">
      <div class="search-input-icon">
        <feather-icon icon="SearchIcon" />
      </div>
      <!-- <input type="text" placeholder="Explore Vuexy...." class="form-control-input"> -->
      <!-- @keyup.esc="escPressed" -->
      <!-- @keyup.enter="suggestionSelected" -->
      <b-form-input
        class="ml-4"
        v-if="showSearchBar"
        v-model="searchQuery"
        :placeholder="$t('Tìm kiếm sản phẩm')"
        autofocus
        autocomplete="off"
        debounce="500"
        @keyup.esc="
          isMobile ? showSearchBar = false : showSearchBar = true;
          searchHistory = '';
          resetsearchQuery();
        "
        @keyup.enter="() => toSearchResultByKeyword()"
        @update="handleSearch"
      />
      <div
        v-if="(!isMobile && searchQuery) || (isMobile) "
        class="search-input-close"
        @click="
          () => {
            showSearchBar = isMobile ? false : true;
            resetsearchQuery();
          }
        "
      >
        <feather-icon icon="XIcon" />
      </div>

      <!-- Suggestions List -->
      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="search-list search-list-main scroll-area overflow-hidden"
        :class="{ show: searchQuery  }"
        tagname="ul"
      >
        <template v-if="!searchQuery">
          <li
            v-for="(search, index) in searchHistory"
            :key="index"
            class="suggestion-group-suggestion cursor-pointer px-4"
            :class="{
              'suggestion-current-selected': currentSelected === `${index}`,
            }"
            @mouseenter="currentSelected = `${index}`"
            @click="toSearchResultByKeyword(search)"
          >
            <span class="align-middle ml-1 font-medium">{{ search }}</span>
          </li>
        </template>
        <!-- <li
          v-for="(suggestion_list, grp_name, grp_index) in filteredData"
          :key="grp_index"
          class="suggestions-groups-list"
        >
        Suggestion List of each group-->
        <template v-if="searchQuery">
          <li
            v-for="(suggestion, index) in listProducts"
            :key="index"
            class="suggestion-group-suggestion cursor-pointer md:px-4"
            :class="{
              'suggestion-current-selected': currentSelected === `${index}`,
            }"
            @mouseenter="currentSelected = `${index}`"
            @click="goToDetail(suggestion)"
          >
            <div class="p-0 flex justify-between items-center" style="gap: 24px">
              <div class="flex gap-1 items-center">
                <img :src="suggestion.primary_image" alt style="width: 40px; height: 40px" />
                <span class="align-middle ml-1 font-medium">
                  {{
                  suggestion.name
                  }}
                </span>
              </div>
              <div class="text-right">
                <span>Thương hiệu:</span>
                <span
                  style="color: #20419b; margin-left: 4px"
                  class="uppercase"
                >{{ suggestion.brands && suggestion.brands.name }}</span>
              </div>
            </div>
          </li>
        </template>

        <li
          v-if="!listProducts.length && searchQuery"
          class="suggestion-group-suggestion no-results"
        >
          <p>No Results Found.</p>
        </li>
        <!-- </li> -->
      </vue-perfect-scrollbar>
    </div>
  </li>
</template>

<script>
import router from '@/router'
import store from '@/store'
import useAutoSuggest from '@core/components/app-auto-suggest/useAutoSuggest'
import searchAndBookmarkData from '@core/layouts/components/app-navbar/search-and-bookmark-data'
import { title } from '@core/utils/filter'
import { ref, watch, onMounted, reactive } from '@vue/composition-api'
import { BAvatar, BFormInput, BImg, BLink } from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import appUtils from '../../utils/appUtils'
import EventBus from '@/utils/EventBus'

export default {
  components: {
    BFormInput,
    BLink,
    BImg,
    BAvatar,
    VuePerfectScrollbar
  },
  props: {
    isShow: Boolean,
    isMobile: Boolean
  },
  setup(props) {
    // onMounted(() => {
    //   fetchProducts()
    // })

    const listProducts = ref([])
    const searchHistory = ref([])
    const showSearchBar = ref(props.isShow)

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60
    }

    const suggestionSelected = (grpName, suggestion) => {
      // If parameter is not provided => Use current selected
      if (!suggestion) {
        // If currentSelected value is -1 => No value/item is selected (Prevent Errors)
        /* eslint-disable no-use-before-define, no-param-reassign */
        if (currentSelected.value !== -1) {
          /* eslint-disable no-use-before-define, no-param-reassign */
          const [grpIndex, itemIndex] = currentSelected.value.split('.')
          grpName = Object.keys(filteredData.value)[grpIndex]
          suggestion = filteredData.value[grpName][itemIndex]
          /* eslint-enable */
        }
      }
      if (grpName === 'pages') router.push(suggestion.route).catch(() => {})
      // eslint-disable-next-line no-use-before-define
      resetsearchQuery()
      showSearchBar.value = false
    }

    const { searchQuery, resetsearchQuery, filteredData } = useAutoSuggest({
      data: searchAndBookmarkData,
      searchLimit: 4
    })

    const handleSearch = text => {
      fetchProducts(text)
    }

    const currentSelected = ref(-1)
    watch(filteredData, val => {
      if (!Object.values(val).some(obj => obj.length)) {
        currentSelected.value = -1
      } else {
        // Auto Select first item if it's not item-404
        let grpIndex = null

        // eslint-disable-next-line no-restricted-syntax
        for (const [index, grpSuggestions] of Object.values(val).entries()) {
          if (grpSuggestions.length) {
            grpIndex = index
            break
          }
        }

        if (grpIndex !== null) currentSelected.value = `${grpIndex}.0`
      }
    })

    const fetchProducts = async text => {
      let params = {}
      params = {
        keyword: text,
        order: 'desc',
        page_num: 1,
        page_size: 100,
        supplier_id: JSON.parse(localStorage.user).supplierInfo.id
      }
      await store
        .dispatch('app-ecommerce/fetchProducts', params)
        .then(res => {
          listProducts.value = res.data.data
        })
        .catch(e => {
          console.log(e)
        })
    }

    const increaseIndex = (val = true) => {
      /* eslint-disable no-lonely-if, no-plusplus */

      // If there's no matching items
      if (!Object.values(filteredData.value).some(grpItems => grpItems.length))
        return

      const [grpIndex, itemIndex] = currentSelected.value.split('.')

      const grpArr = Object.entries(filteredData.value)
      const activeGrpTotalItems = grpArr[grpIndex][1].length

      if (val) {
        // If active item is not of last item in grp
        if (activeGrpTotalItems - 1 > itemIndex) {
          currentSelected.value = `${grpIndex}.${Number(itemIndex) + 1}`

          // If active item grp is not last in grp list
        } else if (grpIndex < grpArr.length - 1) {
          for (let i = Number(grpIndex) + 1; i < grpArr.length; i++) {
            // If navigating group have items => Then move in that group
            if (grpArr[i][1].length > 0) {
              currentSelected.value = `${Number(i)}.0`
              break
            }
          }
        }
      } else {
        // If active item is not of first item in grp
        if (Number(itemIndex)) {
          currentSelected.value = `${grpIndex}.${Number(itemIndex) - 1}`

          // If active item grp  is not first in grp list
        } else if (Number(grpIndex)) {
          for (let i = Number(grpIndex) - 1; i >= 0; i--) {
            // If navigating group have items => Then move in that group
            if (grpArr[i][1].length > 0) {
              currentSelected.value = `${i}.${grpArr[i][1].length - 1}`
              break
            }
          }
        }
      }
      /* eslint-enable no-lonely-if, no-plusplus */
    }
    const goToDetail = data => {
      router.push({ path: `/marketplace/${data.slug}` })
      if (props.isMobile) showSearchBar.value = false
      else showSearchBar.value = true
      resetsearchQuery()
    }
    const getSearchHistory = () => {
      searchHistory.value = JSON.parse(localStorage.getItem('SearchHistory'))
    }

    const toSearchResultByKeyword = searchQuerySelected => {
      if (searchQuerySelected) {
        if (router.currentRoute.name === 'Marketplace') {
          EventBus.$emit('showSearchResults', searchQuerySelected)
        } else {
          router.push({
            name: `Marketplace`,
            query: { search: searchQuerySelected }
          })
        }
        searchQuery.value = ''
      } else if (searchQuery.value) {
        if (router.currentRoute.name === 'Marketplace') {
          searchHistory.value = JSON.parse(
            localStorage.getItem('SearchHistory')
          )
            ? JSON.parse(localStorage.getItem('SearchHistory'))
            : []
          searchHistory.value.push(searchQuery.value)
          if (searchHistory.value.length === 6) {
            searchHistory.value.shift()
          }
          localStorage.setItem(
            'SearchHistory',
            JSON.stringify(searchHistory.value)
          )
          EventBus.$emit('showSearchResults', searchQuery.value)
        } else {
          router.push({
            name: `Marketplace`,
            query: { search: searchQuery.value }
          })
        }
        searchQuery.value = ''
      }
      if (props.isMobile) showSearchBar.value = false
      else {
        showSearchBar.value = true
      }
    }

    return {
      appUtils,
      showSearchBar,
      perfectScrollbarSettings,
      searchAndBookmarkData,
      title,
      suggestionSelected,
      currentSelected,
      increaseIndex,
      fetchProducts,
      // AutoSuggest
      searchQuery,
      resetsearchQuery,
      filteredData,
      listProducts,
      goToDetail,
      handleSearch,
      toSearchResultByKeyword,
      getSearchHistory,
      searchHistory
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
p {
  margin: 0;
}

/* .app-auto-suggest {
  position: relative;
}

.auto-suggest-suggestions-list {
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
  border-radius: 6px;
  position: absolute;
  top: calc(100% + 1rem);
  background-color: white;
  width: 100%;
} */

.suggestion-group-title {
  font-weight: 500;
  padding: 0.75rem 1rem 0.25rem;
}

.suggestion-group-suggestion {
  padding: 0.75rem 1rem;
}

.suggestion-current-selected {
  background-color: $body-bg;

  .dark-layout & {
    background-color: $theme-dark-body-bg;
  }
}

.cs-navbar {
  .search-input {
    top: 50%;
    transform: translateY(-50%);
    border: 1px solid #dedede;
    display: flex;
    align-items: center;
    padding: 0 8px;

    .search-input-icon {
      position: static;
    }

    input {
      padding: 12px;
      flex: 1;
      margin: 0 !important;
    }
  }
}
</style>
