<template>
  <li :class="{
      'active': isActive,
      'disabled': item.disabled
    }">
    <b-link v-bind="linkProps" class="dropdown-item">
      <feather-icon :icon="item.icon || 'CircleIcon'" size="24" />
      <span class="menu-title">{{ t(item.title) }}</span>
    </b-link>
  </li>
</template>

<script>
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import { BLink } from "bootstrap-vue";
import mixinHorizontalNavMenuLink from "./mixinHorizontalNavMenuLink";
import useHorizontalNavMenuLink from "./useHorizontalNavMenuLink";

export default {
  components: {
    BLink
  },
  mixins: [mixinHorizontalNavMenuLink],
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const { isActive, linkProps, updateIsActive } = useHorizontalNavMenuLink(
      props.item
    );

    const { t } = useI18nUtils();

    return {
      isActive,
      linkProps,
      updateIsActive,
      t
    };
  }
};
</script>
