<template>
  <b-nav-item-dropdown
    class="dropdown-cart mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="countNoti.label"
        badge-classes="bg-danger"
        class="text-body"
        icon="ShoppingCartIcon"
        size="21"
      />
    </template>
    <!-- <template #button-content>
      <feather-icon
        :badge="
          $store.state['app-ecommerce'] &&
          $store.state['app-ecommerce'].cartItemsCount
        "
        class="text-body"
        icon="ShoppingCartIcon"
        size="21"
      />
    </template>-->

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">My Cart</h4>
        <b-badge pill variant="light-primary" class="flex items-center">
          {{ listProductsInCart.length ? listProductsInCart.length : 0 }}
          sản phẩm
        </b-badge>
        <!-- <b-badge pill variant="light-primary"
          >{{ countNoti.value }} New</b-badge
        >-->
        <div v-if="$shoppingCart.length" class="ml-1">
          <div
            class="flex items-center justify-end text-gray-400 hover:text-red-500 transition-all duration-300 cursor-pointer"
            @click="appUtils.removeAllCartData()"
          >
            <div class="leading-none">
              <svg
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21 5.98047C17.67 5.65047 14.32 5.48047 10.98 5.48047C9 5.48047 7.02 5.58047 5.04 5.78047L3 5.98047"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M8.5 4.97L8.72 3.66C8.88 2.71 9 2 10.69 2H13.31C15 2 15.13 2.75 15.28 3.67L15.5 4.97"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M18.8499 9.14062L18.1999 19.2106C18.0899 20.7806 17.9999 22.0006 15.2099 22.0006H8.7899C5.9999 22.0006 5.9099 20.7806 5.7999 19.2106L5.1499 9.14062"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M10.3301 16.5H13.6601"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M9.5 12.5H14.5"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <p
              class="fs-16 fw-400 leading-none mb-0"
              style="transform: translateY(2px); margin-left: 4px"
            >
              {{ $t("shopping_cart.lbl_remove_all") }}
            </p>
          </div>
        </div>
      </div>
    </li>

    <!-- Cart Items -->
    <vue-perfect-scrollbar
      v-if="listProductsInCart.length"
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <b-media
        v-for="item in listProductsInCart"
        :key="item.id && item.variant_id"
      >
        <template #aside>
          <b-img
            :src="item.primary_image"
            :alt="item.name"
            rounded
            width="62px"
            @error="(e) => appUtils.onHandleErrorLoadImage(e)"
          />
        </template>
        <!-- <feather-icon
          icon="XIcon"
          class="cart-item-remove cursor-pointer"
          @click.stop="removeItemFromCart(item.id)"
        />-->
        <div class="media-heading flex-2">
          <h6 class="cart-item-title">
            <router-link
              class="text-body text-view-more cursor-pointer"
              :to="`/marketplace/${item.slug}`"
              >{{ item.name }}</router-link
            >
          </h6>
          <!-- <small class="cart-item-by">By {{ item.brands.name }}</small> -->
          <div>
            {{ item.count }} x
            {{
              item.selected_variant &&
              appUtils.numberFormat(item.selected_variant.price)
            }}đ/{{
              item.selected_variant && item.selected_variant.ecom_unit.name
            }}
          </div>
        </div>

        <!-- <div class="cart-item-qty ml-1">
          <b-form-spinbutton v-model="item.qty" min="1" size="sm" />
        </div>-->

        <h5 class="cart-item-price flex-1 font-medium">
          {{
            item.selected_variant &&
            appUtils.numberFormat(item.selected_variant.price * item.count)
          }}đ
        </h5>
      </b-media>
    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
    <!-- <li v-if="listProductsInCart.length" class="dropdown-menu-footer">
      <div class="d-flex justify-content-between mb-1">
        <h6 class="font-weight-bolder mb-0">Total:</h6>
        <h6 class="text-primary font-weight-bolder mb-0">${{ totalAmount }}</h6>
      </div>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
        :to="{ name: 'apps-e-commerce-checkout' }"
        >Checkout</b-button
      >
    </li>-->

    <p
      @click="handleViewCart"
      v-if="listProductsInCart.length"
      class="m-0 p-1 text-center cursor-pointer text-view-more"
    >
      Xem tất cả giỏ hàng
    </p>
    <p v-if="!listProductsInCart.length" class="m-0 p-1 text-center">
      Your cart is empty
    </p>
  </b-nav-item-dropdown>
</template>

<script>
import appUtils from "@/utils/appUtils";
import {
  BBadge,
  BButton,
  BFormSpinbutton,
  BImg,
  BLink,
  BMedia,
  BNavItemDropdown,
} from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BImg,
    BFormSpinbutton,
    VuePerfectScrollbar,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      items: [],
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
    };
  },
  watch: {
    $shoppingCart: {
      handler(data) {
        console.log(data);
      },
      deep: true,
    },
  },
  computed: {
    totalAmount() {
      let total = 0;
      this.listProductsInCart?.forEach((i) => {
        total += i.price;
      });
      return total;
    },
    listProductsInCart() {
      const cartData =
        this.$shoppingCart ||
        JSON.parse(localStorage.getItem("HodoCart") || `[]`);
      return cartData;
      // return (
      //   cartData?.map(elm => {
      //     return {
      //       ...elm,
      //       selected_variant:
      //         elm.variants.find(item => item.conversion_rate == 1) ||
      //         elm.variants[0]
      //     };
      //   }) || []
      // );
    },
    countNoti() {
      if (
        typeof this.listProductsInCart?.length === "undefined" ||
        this.listProductsInCart?.length === 0
      ) {
        return {};
      }

      const count = {
        label:
          this.listProductsInCart?.length < 10 &&
          this.listProductsInCart?.length > 0
            ? this.listProductsInCart?.length
            : `9+`,
        value: this.listProductsInCart?.length || 0,
      };
      return count;
    },
  },
  methods: {
    // fetchItems() {
    //   this.$store
    //     .dispatch('app-ecommerce/fetchCartProducts')
    //     .then((response) => {
    //       this.items = response.data.products
    //     })
    // },
    // removeItemFromCart(productId) {
    //   this.$store
    //     .dispatch('app-ecommerce/removeProductFromCart', { productId })
    //     .then(() => {
    //       const itemIndex = this.items.findIndex((p) => p.id === productId)
    //       this.items.splice(itemIndex, 1)

    //       // Update count in cart items state
    //       this.$store.commit(
    //         'app-ecommerce/UPDATE_CART_ITEMS_COUNT',
    //         this.items.length
    //       )
    //     })
    // },
    handleViewCart() {
      this.$router.push({ path: "/shopping-cart" }).catch(() => {});
    },
  },
  setup() {
    return {
      appUtils,
    };
  },
};
</script>

<style lang="scss" scoped>
.dropdown-cart {
  .media {
    .media-aside {
      align-items: center;
    }
  }
}
.flex-2 {
  flex: 2;
}
.text-view-more {
  &:hover {
    color: #20419b !important;
    text-decoration: underline;
  }
}
</style>
