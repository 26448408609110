export default [
  // {
  //   title: "Shop",
  //   icon: "ShoppingCartIcon",
  //   route: "apps-e-commerce-shop",
  // },
  // {
  //   title: "Marketplace",
  //   icon: "ShoppingCartIcon",
  //   route: "Marketplace",
  // },
  // {
  //   title: "Details",
  //   icon: "",
  //   route: {
  //     name: "apps-e-commerce-product-details",
  //     params: { slug: "apple-watch-series-5-27" },
  //   },
  // },
  // {
  //   title: "Details",
  //   icon: "",
  //   route: {
  //     name: "ProductDetail",
  //     params: { slug: "" },
  //   },
  // },
  // {
  //   title: "Wishlist",
  //   icon: "",
  //   route: "apps-e-commerce-wishlist",
  // },
  // {
  //   title: "Checkout",
  //   icon: "",
  //   route: "apps-e-commerce-checkout",
  // },
  // {
  //   title: "Checkout",
  //   icon: "",
  //   route: "ShoppingCart",
  // },
  {
    title: "Tất cả",
    icon: "null",
    route: "Marketplace",
  },
  {
    title: "Dinh dưỡng",
    icon: "null",
    route: "Marketplace",
    type: 1,
  },
  {
    title: "Thực phẩm chức năng",
    icon: "null",
    route: "Marketplace",
    type: 2,

  },
  {
    title: "Thiết bị y tế",
    icon: "null",
    route: "Marketplace",
    type: 3,

  },
  {
    title: "Thuốc",
    icon: "null",
    route: "Marketplace",
    type: 4,

  },
  {
    title: "Chăm sóc cá nhân",
    icon: "null",
    route: "Marketplace",
    type: 5,
  },
]