<template>
  <div class="navbar-container main-menu-content">
    <horizontal-nav-menu-items :items="navMenuItems" />
  </div>
</template>

<script>
import navMenuItems from '@/navigation/horizontal'
import HorizontalNavMenuItems from './components/horizontal-nav-menu-items/HorizontalNavMenuItems.vue'

export default {
  components: {
    HorizontalNavMenuItems
  },
  setup() {
    return {
      navMenuItems
    }
  },
  data() {
    return {
      loading: false,
      navMenuItems: [
        {
          id: 0,
          priority: 0,
          name: 'Trang chủ'
        }
      ]
    }
  },
  async created() {
    await this.fetchProductCategoryList()
  },
  methods: {
    async fetchProductCategoryList() {
      this.loading = true
      const params = {
        page_num: 1,
        page_size: 100,
        supplier_id: JSON.parse(localStorage.user).supplierInfo.id,
        order: 'desc',
        sort_by: 'priority',
        view: 2
      }
      await this.$rf
        .getRequest('EcomRequest')
        .getProductCategoryList(params)
        .then(res => {
          this.loading = false
          const otherItem = {
            header: 'More',
            icon: '',
            type: 100,
            children: [
              ...res.data.data.slice(5).map(item => {
                return {
                  title: item.name,
                  route: 'Marketplace',
                  type: item.id,
                  priority: item.priority
                }
              })
            ]
          }
          res.data.data.filter(item => !item.priority)
          if (otherItem.children.length > 0) {
            this.navMenuItems = [
              ...this.navMenuItems,
              ...res.data.data.slice(0, 5),
              otherItem
            ].map(item => {
              return { ...item, route: 'Marketplace' }
            })
          } else {
            this.navMenuItems = [
              ...this.navMenuItems,
              ...res.data.data.slice(0, 5)
            ].map(item => {
              return { ...item, route: 'Marketplace' }
            })
          }
        })
        .catch(e => console.log(e))
      this.loading = false
    }
  }
}
</script>

<style lang="scss">
@import '~@core/scss/base/core/menu/menu-types/horizontal-menu.scss';
</style>
