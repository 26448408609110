import { ref } from '@vue/composition-api'
import { isNavLinkActive, navLinkProps } from '@core/layouts/utils'
import router from '@/router'

export default function useHorizontalNavMenuLink(item) {
  const isActive = ref(false)

  const linkProps = navLinkProps(item)

  const updateIsActive = () => {
    const isType = String(router.currentRoute.query?.type) === String(item.type)
    isActive.value = Number(router.currentRoute.query?.type) ? isNavLinkActive(item) && isType : Number(item.id)===0
  }

  return {
    isActive,
    linkProps,
    updateIsActive,
  }
}
