<template>
  <div class="navbar-container d-flex content align-items-center flex-wrap">
    <custom-navbar-brand />

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <!-- <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <bookmarks />
    </div>-->
    <div class="flex-1 relative mx-1 d-none d-sm-block">
      <custom-search-bar :isShow="true" :isMobile="false" />
    </div>
    <!-- Right Col -->
    <b-navbar-nav class="nav align-items-center ml-auto gap-2" style>
      <!-- <locale /> -->

      <div class="d-block d-sm-none">
        <custom-search-bar :isShow="false" isMobile />
      </div>
      <custom-cart-dropdown />
      <!-- <custom-notification-dropdown /> -->
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <!-- <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">{{ user.name }}</p>
          </div>-->
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="appUtils.getImageURL(user.avatar)"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <b-dropdown-item link-class="d-flex align-items-center">
          <span class="font-weight-bolder mb-0">{{ user.name }}</span>
        </b-dropdown-item>
        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="goToUserProfile('address')"
        >
          <feather-icon size="16" icon="UserIcon" class="mr-50" />
          <span>{{ $t("lbl_account") }}</span>
        </b-dropdown-item>
        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="goToUserProfile('order')"
        >
          <feather-icon size="16" icon="ShoppingCartIcon" class="mr-50" />
          <span>{{ $t("lbl_my_order") }}</span>
        </b-dropdown-item>
        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logout()"
        >
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>{{ $t("lbl_logout") }}</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import CustomCartDropdown from "@/layouts/components/CustomCartDropdown.vue";
import CustomNavbarBrand from "@/layouts/components/CustomNavbarBrand.vue";
import CustomNotificationDropdown from "@/layouts/components/CustomNotificationDropdown.vue";
import CustomSearchBar from "@/layouts/components/CustomSearchBar.vue";
import store from "@/store";
import Locale from "@core/layouts/components/app-navbar/components/Locale.vue";
import { BLink, BNavbarNav } from "bootstrap-vue";
import { deleteToken, getMessaging } from "firebase/messaging";
import { mapGetters } from "vuex";
import appUtils from "@/utils/appUtils";

export default {
  components: {
    BLink,

    // Navbar Components
    BNavbarNav,
    Locale,
    CustomSearchBar,
    CustomCartDropdown,
    CustomNotificationDropdown,
    CustomNavbarBrand,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      appUtils,
    };
  },
  computed: {
    ...mapGetters({
      userLogin: "authenticate/userLogin",
    }),
    user() {
      return (this.userLogin?.id && this.userLogin) || this.$user || {};
    },
  },
  methods: {
    async logout() {
      await store.dispatch("authenticate/logout", () => {
        this.$router.replace({ name: "welcome" });
        deleteToken(getMessaging());
      });
    },
    goToUserProfile(menuKey) {
      if (
        this.$route.name === "UserProfile" &&
        this.$route.query?.menu === menuKey
      )
        return;
      else
        this.$router.replace({ name: "UserProfile", query: { menu: menuKey } });
    },
  },
};
</script>

<style lang="scss">
.header-navbar .navbar-container ul.navbar-nav li.dropdown-user .dropdown-menu {
  width: auto;
  min-width: 12rem;
}

// @media (min-width: 400px) {
//   .cs-nav {
//     position: relative;
//   }
// }
</style>
